<template>
    <section class="shop">

        <div class="container-fluid cont__pd">
            <div class="shop__top__container">
                <button class="backFromShop" onclick="window.location.href = '/'">
                    <img src="@/assets/img/arrowShopBack.svg" alt="">
                    <span>Назад</span>
                </button>
                <div class="shop__top__container__img">
                    <img src="@/assets/img/ShopBtnMain.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-12">
                    <div class="d-flex catalog__topfilters">

                        <input type="text" class="catalog__search" placeholder="Поиск по товарам" v-model.trim="search">

                        <div class="filtcontm">
                            <span class="filters__spans">Игра</span>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle dropdown__filters" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ games[gameSelect].name }}
                                    <img src="@/assets/img/arrowsFilter.svg" class="catalog__ArrowImg" alt="">
                                </button>
                                <div class="dropdown-menu catalog__dropmenu " aria-labelledby="dropdownMenuButton">
                                    <a href="#" v-for="game in games" :key="game.id"
                                        :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': game.id != 1 }, { 'active': game.id == this.gameSelect }]"
                                        @click="this.gameSelect = game.id">
                                        {{ game.name }}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="filtcontm" v-if="gameSelect == 0">
                            <span class="filters__spans">Редкость</span>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle dropdown__filters" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ rarityList[rareSelect].rare }}
                                    <img src="@/assets/img/arrowsFilter.svg" class="catalog__ArrowImg" alt="">
                                </button>
                                <div class="dropdown-menu catalog__dropmenu " aria-labelledby="dropdownMenuButton">

                                    <a href="#" v-for="rarity in rarityList" :key="rarity.id"
                                        :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': rarity.id != 5 }, { 'active': rarity.id == this.rareSelect }]"
                                        @click="this.rareSelect = rarity.id">
                                        {{ rarity.rare }}
                                    </a>

                                </div>
                            </div>
                        </div>

                        <div class="filtcontm" v-if="gameSelect == 0">
                            <span class="filters__spans">Состояние</span>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle dropdown__filters" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ conditionList[conditionSelect].cond }}
                                    <img src="@/assets/img/arrowsFilter.svg" class="catalog__ArrowImg" alt="">
                                </button>
                                <div class="dropdown-menu catalog__dropmenu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item catalog__dropitem" v-for="condition in conditionList"
                                        :key="condition.id" @click="this.conditionSelect = condition.id"
                                        :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': condition.id != 5 }, { 'active': condition.id == this.conditionSelect }]">
                                        {{ condition.cond }}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- DOTA RARE -->
                        <div class="filtcontm" v-if="gameSelect == 1">
                            <span class="filters__spans">Редкость</span>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle dropdown__filters" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ dotaRareList[dotaRareSelect].rare }}
                                    <img src="@/assets/img/arrowsFilter.svg" class="catalog__ArrowImg" alt="">
                                </button>
                                <div class="dropdown-menu catalog__dropmenu " aria-labelledby="dropdownMenuButton">

                                    <a href="#" v-for="rarity in dotaRareList" :key="rarity.id"
                                        :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': rarity.id != 8 }, { 'active': rarity.id == this.dotaRareSelect }]"
                                        @click="this.dotaRareSelect = rarity.id">
                                        {{ rarity.rare }}
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="filtcontm" v-if="gameSelect == 1">
                            <span class="filters__spans">Персонаж</span>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle dropdown__filters" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ dotaHeroesList[dotaHeroesSelect] }}
                                    <img src="@/assets/img/arrowsFilter.svg" class="catalog__ArrowImg" alt="">
                                </button>
                                <div class="dropdown-menu catalog__dropmenu" style="height: 200px; overflow-y: auto;"
                                    aria-labelledby="dropdownMenuButton">

                                    <a href="#" v-for="(rarity, ind) in dotaHeroesList" :key="ind"
                                        :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': rarity.ind != 118 }, { 'active': ind == this.dotaHeroesSelect }]"
                                        @click="this.dotaHeroesSelect = ind">
                                        {{ rarity }}
                                    </a>

                                </div>
                            </div>
                        </div>
                        <!-- DOTA RARE -->

                        <div class="prices__container">
                            <span class="filters__spans">Цена</span>
                            <div class="filter__prices">
                                <input type="range" class="styled-slider slider-progress" min="0"
                                    :max="gameSelect == 1 ? maxPrice : maxPriceCs" v-model="rangeValue">
                            </div>
                            <div class="d-flex justify-content-between mt-3 w-100 range__spans font2">
                                <span>0 ₸</span>
                                <span>{{ rangeValue }} ₸</span>
                            </div>
                        </div>

                        <button class="btn filter__button">
                            Применить фильтр
                        </button>

                        <img src="@/assets/img/dotaAssasinFilters.png" style="width: 100%; margin-top: auto;" alt="">


                    </div>
                </div>

                <div class="col-12 col-md-9">
                    <div class="preshop-container">
                        <div class="shop-container">
                            <div class="row filtersAndCatalog">
                                <div class="col-12 col-md-12 catalog" v-if="gameSelect == 0">
                                    <!-- <div class="row"> -->
                                    <!-- <div class="col-2" > -->
                                    <div class="item font2" v-for="item in pageWeaponsList" :key="item.id"
                                        @click="openModal(item.id)">
                                        <div class="item__top">
                                            <div class="d-flex flex-column">
                                                <div class="item__rare">
                                                    {{ item.rarity }}
                                                </div>

                                            </div>

                                        </div>
                                        <!-- <img src="@/assets/img/m4.png" class="item__img" alt=""> -->
                                        <img :src="item.image" class="item__img" alt="">
                                        <div class="item__bottomcont">
                                            <div class="item__bottom">
                                                <span class="item__name font2">
                                                    {{ item.gun }} {{ item.name }}
                                                </span>
                                                <div>
                                                    {{ item.state }}
                                                </div>
                                            </div>
                                            <span class="item__price font2">
                                                {{ Math.floor(item.cost) }} ₸
                                            </span>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <!-- </div> -->
                                </div>
                                <div class="col-12 col-md-12 catalog" v-else>
                                    <div class="item font2" v-for="item in pageWeaponsList" :key="item.id"
                                        @click="openModal(item.id)">
                                        <div class="item__top">
                                            <div class="d-flex flex-column">
                                                <div class="item__rare">
                                                    {{ item.rarity }}
                                                </div>

                                            </div>

                                        </div>
                                        <img :src="item.img" class="item__img" alt="">
                                        <!-- <img :src="'/data/'+item.item_model_id" class="item__img" alt=""> -->
                                        <div class="item__bottomcont">
                                            <div class="item__bottom">
                                                <span class="item__name font2">
                                                    {{ item.fullName }}
                                                </span>
                                                <!-- <div>
                                                {{item.quality}}
                                            </div> -->
                                            </div>
                                            <span class="item__price font2">
                                                {{ Math.floor(item.price) }} ₸
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pagination__precont">
                            <div class="pagination__cont">
                                <button class="pagination__prev" @click="prevPage">
                                    Назад
                                </button>
                                <div class="pagination__numbers">
                                    {{ this.pageNumber }} / {{ this.MaxPages }}
                                </div>
                                <button class="pagination__next" @click="nextPage">
                                    Вперед
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>


        <div class="container-fluid cont__pd mt-4">


        </div>

        <div class="container-fluid cont__pd mt-4">

        </div>
    </section>
    <div class="d-flex justify-content-center mt-4 mb-4">
        <img src="@/assets/img/sps.png" class="methods" alt="">
    </div>
    <product-modal :product="modal.modalProductName"></product-modal>
</template>

<script>
import ProductModal from '@/components/ProductModal.vue';
import GetDota from '@/assets/js/dotaItems.js'
export default {
    data() {
        return {
            pokemons: [],
            modal: {
                modalProductName: {},
            },
            games: [
                {
                    id: 0,
                    name: 'CS:GO',
                    type: 'cs',
                },
                {
                    id: 1,
                    name: 'Dota 2',
                    type: 'dota'
                }
            ],
            gameSelect: 1,
            sort: 'По умолчанию',
            rarityList: [
                { id: 0, rare: 'Любое' },
                { id: 1, rare: 'Армейское качество' },
                { id: 2, rare: 'Запрещённое' },
                { id: 3, rare: 'Засекреченное' },
                { id: 4, rare: 'Тайное' },
                { id: 5, rare: 'Экстра' },
            ],
            conditionList: [
                { id: 0, cond: 'Любое', short: 'All' },
                { id: 1, cond: 'Factory New', short: 'FN', float: 0 },
                { id: 2, cond: 'Minimal Wear', short: 'MW', float: 0.2 },
                { id: 3, cond: 'Field-Tested', short: 'FT', float: 0.4 },
                { id: 4, cond: 'Well-Worn', short: 'WW', float: 0.6 },
                { id: 5, cond: 'Battle-Scarred', short: 'BS', float: 0.8 },
            ],
            pageNumber: 1,
            weapons: [],
            // weapons: [
            //     {
            //         id: 0,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Армейское',
            //         name: 'Karambit',
            //         pattern: 908,
            //         price: 1000,
            //         float: 0.5,
            //         collection: 'Red',
            //     },
            //     {
            //         id: 1,
            //         img: require('../assets/img/tacti__cart.png'),
            //         rarity: 'Запрещенное',
            //         name: 'AK-47',
            //         pattern: 908,
            //         price: 2500,
            //         float: 0.3,
            //         collection: 'Red-Line',
            //     },
            //     {
            //         id: 2,
            //         img: require('../assets/img/voi__cart.png'),
            //         rarity: 'Засекреченное',
            //         name: 'USP',
            //         pattern: 908,
            //         price: 7000,
            //         float: 0.7,
            //         collection: 'Dark',
            //     },
            //     {
            //         id: 3,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Тайное',
            //         name: 'AWP',
            //         pattern: 908,
            //         price: 25000,
            //         float: 0.79,
            //         collection: 'Dragon Lore',
            //     },
            //     {
            //         id: 4,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Армейское',
            //         name: 'Karambit',
            //         pattern: 908,
            //         price: 55000,
            //         float: 0.1,
            //         collection: 'Red',
            //     },
            //     {
            //         id: 5,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Запрещенное',
            //         name: 'AK-47',
            //         pattern: 908,
            //         price: 35000,
            //         float: 0.2,
            //         collection: 'Red-Line',
            //     },
            //     {
            //         id: 6,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Засекреченное',
            //         name: 'USP',
            //         pattern: 908,
            //         price: 255000,
            //         float: 0.45,
            //         collection: 'Dark',
            //     },
            //     {
            //         id: 7,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Тайное',
            //         name: 'AWP',
            //         pattern: 908,
            //         price: 152487,
            //         float: 0.14,
            //         collection: 'Dragon Lore',
            //     },
            //     {
            //         id: 8,
            //         img: require('../assets/img/cataloitem_1.png'),
            //         rarity: 'Тайное',
            //         name: 'AWP',
            //         pattern: 908,
            //         price: 16000,
            //         float: 0.34,
            //         collection: 'Dragon Lore',

            //     },


            // ],
            search: '',
            lowprice: 0,
            highprice: '',
            rareSelect: 0,
            conditionSelect: 0,
            rangeValue: 100,
            dotaItems: [],
            dotaRareList: [
                { id: 0, rare: 'Любое' },
                {
                    id: 1,
                    rare: 'Common'
                },
                {
                    id: 2,
                    rare: 'Uncommon'
                },
                {
                    id: 3,
                    rare: 'Rare'
                },
                {
                    id: 4,
                    rare: 'Mythical'
                },
                {
                    id: 5,
                    rare: 'Legendary'
                },
                {
                    id: 6,
                    rare: 'Immortal'
                },
                {
                    id: 7,
                    rare: 'Arcana'
                },
                {
                    id: 8,
                    rare: 'Ancient'
                },
            ],
            dotaRareSelect: 0,
            dotaHeroesList: [],
            dotaHeroesSelect: 0,
        }
    },

    methods: {
        getData() {
            const path = 'https://cs-market.kg/api/items';
            axios.get(path)
                .then((res) => {
                    this.weapons = res.data;
                    console.log(res.data);
                })
                .catch((error) => {
                    // eslint-выключение следующей строки
                    console.error(error);
                });
        },
        openModal(id) {
            // console.log(this.weapons.filter(item => item.id == id)[0].name)
            console.log(id);
            if (this.gameSelect == 0) {
                let product = this.weapons.filter(item => item.id == id)[0];
                product.game = 'cs'
                this.modal.modalProductName = product
            }
            else {
                let product = this.dotaItems.filter(item => item.id == id)[0];
                product.game = 'dota'
                this.modal.modalProductName = product
            }
            console.log(this.modal.modalProductName)

            $('#ProductModal').modal('show')
        },
        reloadCatalog() {
            this.search = '';
            this.lowprice = '';
            this.highprice = ''
            this.rareSelect = 0;
            this.conditionSelect = 0;
            this.sort = 'По умолчанию';
        },
        addRarity(id) {
            this.rareSelect.push(id)
        },
        removeRarity(id) {
            this.rareSelect.splice(this.rareSelect.indexOf(id), 1)
            // console.log(this.rareSelect) 
        },
        nextPage() {
            if (this.pageNumber < this.filteredWeapons.length / 20) {
                this.pageNumber = this.pageNumber + 1;
                // console.log(this.pageNumber)
            }
        },
        prevPage() {
            if (this.pageNumber > 1) {
                this.pageNumber = this.pageNumber - 1;
                // console.log(this.pageNumber)
            }
        },
    },
    computed: {
        filteredWeapons() {
            if (this.gameSelect == 0) {
                let searched = this.weapons.filter((product) => {
                    if (this.lowprice === '') {
                        return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                    }
                    else {
                        return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && product.cost > this.lowprice;
                    }
                });
                if (this.rangeValue !== 0) {
                    searched = searched.filter((product) => {
                        return product.cost <= Number(this.rangeValue)
                    })
                };
                if (this.rareSelect != 0) {
                    searched = searched.filter(product => {
                        return product.rarity.toLowerCase() == (this.rarityList[this.rareSelect].rare.toLowerCase())
                    })
                }
                if (this.conditionSelect != 0) {
                    searched = searched.filter(product => {
                        return product.quality == (this.conditionList[this.conditionSelect].short)
                    })
                }
                if (this.sort === 'По цене (Min)') {
                    searched.sort((a, b) => {
                        return a.cost - b.cost
                    })
                }
                if (this.sort === 'По цене (Max)') {
                    searched.sort((a, b) => {
                        return b.cost - a.cost
                    })
                }

                // console.log(searched[0])


                return searched
            }

            else {
                let searched = this.dotaItems
                searched = searched.filter((product) => {
                    if (product.fullName !== undefined) {
                        return String(product.fullName).toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                    };
                    // return product.fullName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                });

                if (this.rangeValue !== 0) {
                    searched = searched.filter((product) => {
                        return product.price <= Number(this.rangeValue)
                    })
                };

                if (this.dotaRareSelect != 0) {
                    searched = searched.filter(product => {
                        return product.rarity.toLowerCase() == (this.dotaRareList[this.dotaRareSelect].rare.toLowerCase())
                    })
                }

                if (this.dotaHeroesSelect != 0) {
                    searched = searched.filter(product => {
                        return product.heroes[0].toLowerCase() == (this.dotaHeroesList[this.dotaHeroesSelect].toLowerCase())
                    })
                }

                return searched
            }

        },
        pageWeaponsList() {
            if (this.filteredWeapons.length > 0) {
                let pageWeapons = [];
                let ind = 0;
                if (this.pageNumber > 1) {
                    ind = (this.pageNumber - 1) * 20
                }
                for (let i = ind; i <= ind + 19 && i < this.filteredWeapons.length; i++) {
                    pageWeapons.push(this.filteredWeapons[i])
                }
                // console.log(pageWeapons)
                return pageWeapons;
            }
        },
        MaxPages() {
            return Math.ceil(this.filteredWeapons.length / 20);
        },
        maxPrice() {
            if (this.dotaItems.length != 0) {
                let max = Math.ceil(this.dotaItems.sort((a, b) => { return b.price - a.price })[0].price)
                return max
            }
            else {
                // console.log('asd')
                return 100
            }
        },
        maxPriceCs() {
            if (this.weapons.length != 0) {
                let max = Math.ceil(this.weapons.sort((a, b) => { return b.cost - a.cost })[0].cost)
                return max
            }
            else {
                // console.log('asd')
                return 100
            }
        },
    },
    mounted() {
        this.getData();

        for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min == '' ? '0' : e.min);
            e.style.setProperty('--max', e.max == '' ? '100' : e.max);
            e.addEventListener('input', () => e.style.setProperty('--value', e.value));
        }

        // this.dotaItems = GetDota();
        let dotaCards = GetDota();
        let heroes = [];
        for (let key in dotaCards.heroes) {
            heroes.push(String(key))
            this.dotaItems = this.dotaItems.concat(dotaCards.heroes[key])
        }
        this.dotaHeroesList.push('Любой')
        this.dotaHeroesList = this.dotaHeroesList.concat(heroes)
        console.log(heroes);
        console.log(this.dotaItems);
    },
    components: { ProductModal },
    watch: {
        maxPrice(newPrice) {
            if (newPrice > 100) {
                for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
                    // e.style.setProperty('--value', e.value);
                    e.style.setProperty('--min', e.min == '' ? '0' : e.min);
                    e.style.setProperty('--max', e.max == '' ? newPrice : newPrice);
                    this.rangeValue = newPrice;
                    e.style.setProperty('--value', newPrice);
                    e.addEventListener('input', () => e.style.setProperty('--value', e.value));
                }
            }
        }
    }
}
</script>

<style scoped>
.methods {
    width: 35.156vw;
}

.shop__top__container {
    display: flex;
    margin-bottom: 2.60vw;
}

.shop__top__container__img {
    flex-grow: 1;
    text-align: center;
}

.shop__top__container__img img {
    width: 316px;
    height: 60px;
}

.backFromShop {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 20px;
    padding: 0px 40px;
    background: rgba(143, 25, 13, 0.4);
    border-radius: 5px;
    border: none;
    height: 60px;
    line-height: 60px;
}

.pagination__precont {
    /* background-color: #1A1814; */
    padding: 1.875vw 16px;

}

.pagination__cont {
    display: flex;
    justify-content: space-between;
    /* background-color: #1E1D21; */
    align-items: center;
    border-radius: 5px;
}

.pagination__prev {
    background-color: unset;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.pagination__next {
    background-color: unset;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.pagination__numbers {
    color: #FFFFFF;
    font-size: 1.250vw;
}


.cont__pd {
    padding-left: 5.208vw;
    padding-right: 5.208vw;
}

.filters__container {
    padding: 17px 24px;
    background: #2B2E37;
    border-radius: 5px;
    /* font-family: 'Roboto', sans-serif; */
}

.shop {
    padding-top: 160px;
    /* background: no-repeat right/80% url(@/assets/img/SolderBack.png) #141721; */
    /* background-color: #1A1814; */
}

.preshop-container {
    /* background: rgba(234, 196, 139, 0.5); */
    border-radius: 5px;
    /* padding: 1px; */
    border: 1.5px solid rgba(143, 25, 13, 1);
}

.shop-container {
    background-color: rgba(22, 22, 29, 1);
    /* width: 96vw; */
    padding: 16px;
    /* border-radius: 5px; */
    /* margin-left: 30px; */
    /* margin-right: 30px; */
}


.catalog__reloadImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.catalog__lineImg {
    margin-left: 0.563vw;
    margin-right: 0.563vw;
}

.dropdown__catalog {
    color: white;
    font-weight: 500;
    font-size: 1.250vw;
    background-color: unset;
}

.dropdown__filters {
    /* margin-top: 15px; */
    color: white;
    font-weight: 400;
    font-size: 1.042vw;
    /* font-family: 'Roboto', sans-serif; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #1E1D21;
    margin-top: 13px;
}

.dropdown__catalog::after {
    display: none;
}

.dropdown__filters::after {
    display: none;
}

.dropdown__filters:focus {
    box-shadow: 0 0 0 0.1rem rgba(143, 25, 13, 1);
}

.catalog__ArrowImg {
    width: 10px;
    margin-left: 16px;
}

.dropdown__catalog:focus {
    box-shadow: 0 0 0 0.2rem #3F7AF5;
}

.catalog__dropmenu {
    background-color: rgba(22, 22, 29, 1);
    box-shadow: 0px 2px 8px 0px rgba(143, 25, 13, 0.4);
    color: white;
    padding: 0;
}

.catalog__dropitem {
    color: white;
    font-size: 20px;
}

.catalog__dropitemBorder {
    border-bottom: 1px solid white;
}

.catalog__dropmenu .active {
    background-color: rgba(143, 25, 13, 1);
    color: white;
    /* border: 1px solid #753ef9; */
}

.catalog__dropitem:hover {
    color: white;
    font-size: 20px;
    background-color: rgba(143, 25, 13, 0.4);
    cursor: pointer;
}

.catalog__search {
    background-color: #1E1D21;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: url(@/assets/img/loopCatalogM.png) no-repeat 10px center #1E1D21;
    background-size: 1.250vw;
    color: white;
    padding-left: 45px;
    width: 100%;
    height: 2.865vw;
    font-size: 24px;
    font-weight: 500;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    /* font-family: 'Roboto', sans-serif; */
}

.catalog__search::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 20px;
    font-weight: 500;
    text-align: left;
}


/* FILTERS */
.filtersAndCatalog {
    /* margin-top: 30px; */
    /* padding-bottom: 2.6vw;     */
}

.filters {
    color: white;
    font-weight: 600;
    font-size: 1.250vw;
}

.filter__prices {
    color: white;
    display: flex;
    align-items: center;
}

.prices__container {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}

.price__min {
    width: 8.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    /* background-color: #171717; */
    border: 1px solid #EAC48B;
    background: url(@/assets/img/tenge.png) no-repeat right 5px center #1E1D21;
    padding-right: 30px;
    color: white;
    background-size: 0.563vw;

}

.price__max {
    width: 8.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    /* background-color: #171717; */
    border: 1px solid #EAC48B;
    background: url(@/assets/img/tenge.png) no-repeat right 5px center #1E1D21;
    padding-right: 30px;
    color: white;
    background-size: 0.563vw;
}

.filters__spans {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 1.042vw;
    margin-right: 16px;
    margin-top: 1.667vw;
}

.filtcontm {
    margin-top: 1.042vw;
}


/* RARITY */

.rarity {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.rarity__btn {
    color: white;
    padding: 0 0;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;

}

.rarity__btn:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.rarity .card {
    background-color: unset;
    padding: 0;
    border: 0;
}

.cheks__label {
    display: flex;
    align-items: center;
}

.customCheckbox {
    float: left;
    position: relative;
    width: 24px;
    height: 24px;
    background-color: unset;
    border-radius: 2px;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    /*margin-right: 5px;*/
    margin-right: 0.26vw;
    overflow: hidden;
}

.customCheckbox.customCheckboxChecked {
    background: #753ef9;
    border: 1px solid white;
}

.customCheckbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.customCheckbox span {
    display: none;
    text-align: center;
    line-height: 20px;
    font-size: 90%;
    color: #222;
}

.customCheckbox.customCheckboxChecked span {
    display: block;
}

.cheks__span {
    font-weight: 500;
    font-size: 24px;
    margin-left: 15px;
}

.filter__button {
    color: white;
    background: rgba(143, 25, 13, 0.4);
    border-radius: 5px;
    margin-top: 2vw;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #8F190D;
    border-radius: 5px;
}

/* CATALOG */
.catalog {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.catalog::-webkit-scrollbar-track {
    background-color: #181820;
}

.catalog::-webkit-scrollbar {
    width: 10px;
}

.catalog::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 20px #FF0027;
}

.item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    background: radial-gradient(82.39% 82.39% at 50% 50%, rgba(74, 64, 64, 0.4) 0%, rgba(95, 94, 102, 0.4) 100%);
    border-radius: 20px;
    height: fit-content;
    box-shadow: 2px 4px 10px #00000040;
    height: auto;
}

.item__top {
    /* position: relative; */
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;

}

.item__rare {
    /* position: absolute; */
    /* top: 0; */
    background-color: #6D6D6D;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 0.8vw;
    /* margin-right: 5px; */
    /* text-align: right; */
}

.item__name {
    /* position: absolute; */
    bottom: 0;
    margin-left: 5px;
    font-size: 1.250vw;
    font-weight: 500;
    color: white;
    padding-right: 10px;
    /* font-family: 'Roboto', sans-serif; */
    line-height: 1.5vw;
}

.item__img {
    width: 100%;
    height: 9vw;
}

.item__bottom {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    font-size: 1.250vw;
    line-height: 1.250vw;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
    margin-top: 5px;
}

.item__price {
    font-weight: 600;
    font-size: 1.250vw;
    padding: 0 10px;
    color: white;
    /* font-family: 'Roboto', sans-serif; */
    /* margin-top: 10px; */
}

.item__bottomcont {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item:hover {
    background: radial-gradient(118.5% 92.63% at 50.22% 45.67%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
}

.catalog__topfilters {
    flex-direction: column;
    border: 1px solid #8F190D;
    border-radius: 10px;
    padding: 24px 24px 0px 24px;
    min-height: 1100px;
    height: 100%;
}

@media screen and (max-width:480px) {
    .methods {
        width: 60vw;
    }

    .backFromShop {
        display: none;
    }

    .catalog__search {}

    .catalog {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;
        grid-column-gap: 5px;
    }

    .reloadsort {
        width: 100%;
    }

    .dropdown__catalog {
        font-size: 4.25vw;
    }

    .prices__container {
        width: 100%;
    }

    .price__min {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .price__max {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .filtcontm {
        width: 100%;
    }

    .dropdown__filters {
        font-size: 6.25vw;
    }

    .filter__button {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .item__img {
        height: 38vw;
    }

    .catalog__topfilters {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .catalog__search {
        width: 100%;
        height: 15vw;
    }

    .shop {
        padding-top: 70px;
    }

    .catalog__topfilters img {
        display: none;
    }

    .catalog__topfilters {
        height: unset;
        min-height: unset;
    }

    .catalog__search::placeholder {
        color: white;
        font-size: 24px !important;
        font-weight: 500;
    }

    .price__min::placeholder {
        font-size: 24px !important;
    }

    .price__max::placeholder {
        font-size: 24px !important;
    }

    .catalog__search {
        background-size: 20px;
    }

    .filters__spans {
        font-size: 5.042vw;
    }

    .pagination__prev {
        font-size: 3.25vw;
    }

    .pagination__next {
        font-size: 3.25vw;
    }

    .pagination__numbers {
        font-size: 3.25vw;
    }

    .item__name {
        font-size: 3.25vw;
        line-height: 3.5vw;
    }

    .item__bottom {
        font-size: 3.25vw;
    }

    .item__price {
        font-size: 3.25vw;
    }

    .item__rare {
        font-size: 3vw;
    }
}
</style>

<style>
.styled-slider {
    width: 100%;
    margin-top: 16px;
    --max: 1354879;
    --min: 1;
    background: none;
}

.range__spans {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].styled-slider {
    height: 4px;
    -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
}

input[type=range].styled-slider:focus {
    outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 0 4px 6px #8F190DE6;
    margin-top: calc(4px * 0.5 - 10px * 0.5);
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
    height: 4px;
    border: none;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
    background: linear-gradient(rgba(143, 25, 13, 1), rgba(143, 25, 13, 1)) 0/var(--sx) 100% no-repeat, rgba(255, 255, 255, 0.1);
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 0 4px 6px #8F190DE6;
}

input[type=range].styled-slider::-moz-range-track {
    height: 4px;
    border: none;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
    background: linear-gradient(rgba(143, 25, 13, 1), rgba(143, 25, 13, 1)) 0/var(--sx) 100% no-repeat, rgba(255, 255, 255, 0.1);
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 0 4px 6px #8F190DE6;
    margin-top: 0;
    box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
    height: 4px;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    box-shadow: none;
    box-sizing: border-box;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
    height: 4px;
    border-radius: 0px 0 0 0px;
    margin: -undefined 0 -undefined -undefined;
    background: rgba(143, 25, 13, 1);
    border: none;
    border-right-width: 0;
}
</style>