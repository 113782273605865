<template>
  <div class="stock" style="overflow-x: hidden; overflow-y: hidden">
    <div class="inner">
      <div class="stock__images text-center">
        <p class="header__main">Выбери свою<br> сторону!</p>
        <p class="header__sec">NOMAD GAMES - это лучшие кейсы CS и<br> DOTA с постоянными скидками</p>
        <div class="d-flex justify-content-center button__block">
          <router-link to="cases" @click="setGame('dota'), trackPage('/cases')"><button class="header__button font2"><img
                src="@/assets/img/dota.svg" style="margin-right:10px;width:1.563vw" alt=""> Кейсы DOTA
              2</button></router-link>
          <router-link to="cases" @click="setGame('cs'), trackPage('/cases')"><button class="header__button font2"><img
                src="@/assets/img/cs.svg" style="margin-right:10px; width:1.198vw" alt="">
              Кейсы CS:
              GO</button></router-link>
        </div>
        <div class="d-flex justify-content-center" style="margin-top:50px;">
          <img src="@/assets/img/scroll.svg" style="width:1.406vw; cursor:pointer" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="cases__sales">
    <img src="@/assets/img/sales.svg" style="width: 16.458vw" alt="" />
  </div>
  <div class="container-fluid">
    <div class="cases__block">
      <div class="row" style="margin-top:5.208vw;">
        <div class="col case__content text-center" @click="tryToBuy">
          <img src="@/assets/img/sales/1.png" style="width:11.875vw" alt="">
          <p class="case__name">Dragon Blood кейс</p>
          <button class="case__button font2">Купить за 9 900 ₸</button>
        </div>
        <div class="col case__content text-center" @click="tryToBuy"><img src="@/assets/img/sales/2.png"
            style="width:13.177vw" alt="">
          <p class="case__name" style="margin-top:2.083vw">Bravo кейс</p>
          <button class="case__button font2">Купить за 11 900 ₸</button>
        </div>
        <div class="col case__content text-center" @click="tryToBuy"><img src="@/assets/img/sales/3.png"
            style="width:11.823vw" alt="">
          <p class="case__name">Кейс курьеров</p>
          <button class="case__button font2">Купить за 5 490 ₸</button>
        </div>
        <div class="col case__content text-center" @click="tryToBuy"><img src="@/assets/img/sales/4.png"
            style="width:15.625vw" alt="">
          <p class="case__name">Dust 2 Collection кейс</p>
          <button class="case__button font2">Купить за 11 200 ₸</button>
        </div>
      </div>
      <div class="row" style="margin-top:5.208vw;">
        <div class="col case__content text-center" @click="tryToBuy">
          <img src="@/assets/img/sales/5.png" style="width:16.771vw" alt="">
          <p class="case__name">Lucky кейс</p>
          <button class="case__button font2">Купить за 11 900 ₸</button>
        </div>
        <div class="col case__content text-center" @click="tryToBuy"><img src="@/assets/img/sales/6.png"
            style="width:14.896vw;" alt="">
          <p class="case__name" style="margin-top:3.125vw">Lifestyle кейс</p>
          <button class="case__button font2">Купить за 5 490 ₸</button>
        </div>
        <div class="col case__content text-center" @click="tryToBuy"><img src="@/assets/img/sales/7.png"
            style="width:14.844vw" alt="">
          <p class="case__name">Middle кейс</p>
          <button class="case__button font2">Купить за 11 200 ₸</button>
        </div>
      </div>
    </div>
  </div>
  <div class="whywe">
    <div class="whywe__title">
      <img src="@/assets/img/whywe.svg" style="width: 16.458vw" alt="" />
    </div>
    <div class="whywe__content">
      <div class="whywe__items">
        <img src="@/assets/img/target.png" style="width: 13.177vw" alt="" />
        <p class="whywe__items__title" style="margin-top:3.906vw">Высокая окупаемость</p>
        <p class="whywe__items__desc">
          Пользователи Nomad Games<br> окупаются больше, чем в 2 раза!
        </p>
      </div>
      <div class="whywe__items">
        <img src="@/assets/img/bank.png" style="width: 11.667vw" alt="" />
        <p class="whywe__items__title">Экономия</p>
        <p class="whywe__items__desc">
          Все кейсы дешевле, чем в стиме
        </p>
      </div>
      <div class="whywe__items" style="margin-top:-1.042vw">
        <img src="@/assets/img/sherif.png" style="width: 14.01vw" alt="" />
        <p class="whywe__items__title">Большой выбор кейсов</p>
        <p class="whywe__items__desc">
          На нашем сайте Вы найдете<br> кейсы для CS:GO и DOTA 2 на<br> любой вкус
        </p>
      </div>
    </div>
  </div>
  <div class="popular__text__content">
    <img src="@/assets/img/popular__items.svg" style="width: 21.51vw" alt="" />
  </div>
  <div class="popular__block d-flex">
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">Desert Eagle</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">13 590 ₸ </p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/1.png" style="width:15.156vw;" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">AWP</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">13 590 ₸ </p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/2.png" style="width:13.021vw" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">Bowie Knife</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">$ 12 095 </p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/3.png" class="shit" style="margin-top:-60px;width:17.76vw;" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">Revolver R8</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;"> 81 590 ₸ </p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/4.png" style="width:16.458vw" alt="">
      </div>
    </div>
  </div>
  <div class="popular__block2 d-flex">
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">AUG</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">$ 13 109 </p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/5.png" style="width:19.167vw;" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">AK-47</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">$ 13 109</p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/6.png" style="width:18.906vw;" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">USP-S</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;"> $ 13 109</p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/7.png" style="width:18.125vw;" alt="">
      </div>
    </div>
    <div class="popular__content" @click="tryToBuy">
      <div class="popular__item__info px-3 py-3 text-start">
        <p class="mb-0 font2 item__name">SG 553</p>
        <div class="d-flex align-items-center">
          <p class="mb-0 font2 item__from">от</p>
          <p class="mb-0 font2 item__price" style="margin-left:10px;">$ 13 109</p>
        </div>
      </div>
      <div class="popular__img d-flex justify-content-center">
        <img src="@/assets/img/popular/8.png" style="width:18.802vw;" alt="">
      </div>
    </div>
  </div>
  <div class="stats__block">
    <div class="stats__img">
      <img src="@/assets/img/stats.png" style="width: 72.292vw" alt="" />
    </div>
    <div class="stats">
      <div class="stats__content">
        <div class="text-center">
          <p class="stats__title">Статистика</p>
        </div>
        <div class="stats__block">
          <div style="margin-right: 4.17vw">
            <p class="stats__count">{{ currentlyOpened }}</p>
            <p class="stats__name">Открытых кейсов</p>
          </div>
          <div>
            <p class="stats__count">{{ usersAll }}</p>
            <p class="stats__name">Пользователей</p>
          </div>
        </div>
        <div class="stats__block">
          <div style="margin-right: 6.17vw">
            <p class="stats__count">{{ usersOnline }}</p>
            <p class="stats__name">Онлайн сейчас</p>
          </div>
          <div>
            <p class="stats__count">{{ contracts }}</p>
            <p class="stats__name">Контрактов</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <the-faq></the-faq>
  <div class="d-flex justify-content-center">
    <img src="@/assets/img/sps.png" style="width:35.156vw;" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    contracts() {
      let curDate = new Date();
      return (
        20643 +
        Math.floor(
          (Date.parse(curDate) - Date.parse("2022-04-03T13:51:50.417-07:00")) /
          60 /
          60 /
          24
        )
      );
    },
    currentlyOpened() {
      let curDate = new Date();
      return (
        500126 +
        Math.floor(
          (Date.parse(curDate) - Date.parse("2022-04-03T13:51:50.417-07:00")) /
          60 /
          60 /
          24
        )
      );
    },
    usersAll() {
      let curDate = new Date();
      return (
        12522 +
        Math.floor(
          (Date.parse(curDate) - Date.parse("2022-04-03T13:51:50.417-07:00")) /
          60 /
          60 /
          24
        )
      );
    },
    usersOnline() {
      let curDate = new Date();
      return Math.floor(2000 + Math.random() * (500 - 1) + 1);
    },
  },
  methods: {
    setGame(game) {
      window.localStorage.setItem('game', game)
    },
  }
};
</script>

<style scoped>
.popular__block2 {
  padding-top: 2.135vw;
  padding-left: 3.646vw;
  padding-right: 3.646vw;
}

.popular__block {
  padding-top: 4.792vw;
  padding-left: 3.646vw;
  padding-right: 3.646vw;
}

.item__name {
  font-weight: 700;
  font-size: 1.25vw;
  color: #fff;
}

.item__from {
  font-weight: 400;
  font-size: 1.042vw;
  color: rgba(255, 255, 255, 0.3);

}

.item__price {
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
}

.popular__content:hover {
  background: radial-gradient(100.91% 78.88% at 50% 59.42%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  border-radius: 20px;
}

.popular__content {
  background: radial-gradient(82.39% 82.39% at 50% 50%, rgba(74, 64, 64, 0.4) 0%, rgba(95, 94, 102, 0.4) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  width: 20.833vw;
  height: 18.802vw;
  margin-right: 3.125vw;
  cursor: pointer;
}

.case__content:hover .case__name {
  text-shadow: 0px 0px 8px #8F190D;
}

.case__content:hover .case__button {
  background: radial-gradient(114.32% 89.36% at 49.68% 48.94%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%);
  border-radius: 15px;
}

.case__name {
  font-weight: 400;
  font-size: 1.25vw;
  color: #FFFFFF;

}

.case__content {
  cursor: pointer;
}

.case__button {
  padding: 0.625vw 0.833vw 0.625vw 0.833vw;
  font-weight: 500;
  font-size: 1.042vw;
  color: #fff;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #8F190D;
}

.cases__block {
  padding-left: 10.26vw;
  padding-right: 8.75vw;
}

.button__block {
  padding-top: 5.208vw;
}

.stock__images {
  padding-top: 6.177vw;
  padding-bottom: 3.49vw;
}

.header__button {
  margin-right: 2.083vw;
  margin-left: 1.283vw;
  background: rgba(143, 25, 13, 0.4);
  border: 1px solid rgba(143, 25, 13, 0.8);
  border-radius: 5px;
  font-weight: 400;
  font-size: 1.042vw;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.781vw 1.927vw 0.781vw 1.927vw;
}

.button__block a {
  text-decoration: none;
}

.button__block a:hover {
  text-decoration: none;
}

.header__sec {
  font-weight: 400;
  font-size: 1.458vw;
  color: #FFFFFF;
}

.header__main {
  font-weight: 700;
  color: #FFFFFF;
  font-size: 3.646vw;
}

.stats__img {
  margin-top: 9.9vw;
}

.stats__block {
  position: relative;
}

.stats__name {
  font-family: "Roboto";
  font-weight: 400;
  color: #fff;
  font-size: 1.25vw;
}

.stats__count {
  font-family: "Allerta Stencil";
  font-weight: 400;
  font-size: 3.13vw;
  color: #B51A0B;
}

.stats__title {
  font-size: 2.6vw;
  font-weight: 700;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.stats__block {
  display: flex;
  justify-content: center;
}

.stats {
  top: 13.02vw;
  right: 20.44vw;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}

.whywe__items img {
  margin-left: 3.2vw;
}

.whywe__items__title {
  margin-top: 1.46vw;
  font-size: 1.67vw;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.whywe__items__desc {
  font-size: 1.04vw;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.whywe__content {
  padding-left: 13.02vw;
  padding-right: 13.02vw;
  display: flex;
  justify-content: space-between;
  margin-top: 5.21vw;
}

.whywe {
  margin-top: 7.81vw;
}

.whywe__title {
  display: flex;
  justify-content: center;
}

.mtc {
  margin-top: 1.5rem;
}

.weapon__price {
  font-weight: 400;
  font-size: 1.04vw;
}

.weapon__from {
  margin-right: 0.5vw;
  font-weight: 400;
  font-size: 1.04vw;
  color: rgba(255, 255, 255, 0.3);
}

.weapon__name {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 1.15vw;
}

.weapon__price__content {
  display: flex;
}

.item__block__content:hover {
  background: rgba(241, 90, 36, 0.4);
  border: 1px solid #f15a24;
}

.item__block__content {
  border: 1px solid #6d6d6d;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  width: 17.45vw;
  height: 20.36vw;
  color: #fff;
  padding-top: 2.6vw;
  padding-left: 1.25vw;
  background: radial-gradient(82.39% 82.39% at 50% 50%,
      rgba(30, 29, 33, 0.4) 0%,
      rgba(60, 59, 63, 0.4) 100%);
}

.item__weapons__blocks {
  display: flex;
  justify-content: space-between;
}

.shit {
  margin-top: 2.6vw;
}

.popular__cases__item {
  text-align: center;
}

.cases__item__btn {
  background: #2c6df5;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: 400;
  font-size: 1.25vw;
  padding: 0.63vw 2.6vw 0.63vw 2.6vw;
}

.cases__item__desc {
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
}

.popular__cases__content {
  display: flex;
  align-items: center;
}

.popular__text {
  margin-left: 1.25vw;
  font-weight: 900;
  font-size: 2.08vw;
  color: #fff;
}

.popular__text__content {
  margin-top: 8.85vw;
  display: flex;
  justify-content: center;
}

.popular__items {
  padding-top: 7.81vw;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
}

.case__btn {
  font-family: "Roboto";
  background: rgba(241, 90, 36, 0.4);
  border: 1px solid #f15a24;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
  padding: 0.94vw 3.02vw 0.94vw 3.02vw;
  font-weight: 400;
  font-size: 1.04vw;
  margin-top: 2.08vw;
  margin-bottom: 3.13vw;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% -90%, 0 100%);
  clip-path: polygon(10% 0, 0 100%, 90% 100%, 100% 0);
}

.case__desc {
  font-weight: 400;
  font-size: 1.25vw;
  color: #ffff;
}

.case__frame {
  margin-left: 13.44vw;
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
  background: rgba(44, 109, 245, 0.2);
  border: 1px solid #2c6df5;
  box-sizing: border-box;
  border-radius: 5px;
  width: 5.21vw;
  padding: 0.52vw 1.25vw 0.52vw 1.25vw;
}

.cases__cases {
  padding-left: 1.04vw;
  text-align: center;
  display: flex;
}

.cases__cases2 {
  padding-left: 1.04vw;
  text-align: center;
  display: flex;
  justify-content: center;
}

.cases__href {
  color: #fff;
  font-weight: 400;
  font-size: 1.04vw;
}

.cases__sales__text {
  margin-left: 1.25vw;
  font-weight: 900;
  font-size: 2.08vw;
}

.cases__main {
  padding-left: 2.6vw;
  padding-right: 2.6vw;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.cases__sales {
  margin-top: 8.85vw;
  display: flex;
  justify-content: center;
}

.stock__buttons {
  padding-bottom: 6vw;
  margin-left: -26.79vw;
}

.stock__btnleft {
  background: #2c6df5;
  border-radius: 5px;
  border: 0;
  padding: 1.25vw 2.6vw 1.25vw 2.6vw;
  font-size: 1.04vw;
  color: #fff;
  margin-right: 1.25vw;
}

.stock__btnright {
  background: rgba(241, 90, 36, 0.4);
  border: 1px solid #f15a24;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.09vw 4.58vw 1.09vw 4.58vw;
  font-size: 1.04vw;
  color: #fff;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% -90%, 0 100%);
  clip-path: polygon(10% 0, 0 100%, 90% 100%, 100% 0);
}

.stock__main {
  text-align: left;
  font-style: normal;
  font-weight: 900;
  font-size: 4.17vw;
}

.stock__sec {
  margin-bottom: 4.27vw;
  text-align: left;
  font-weight: 400;
  font-size: 1.25vw;
}

.linkwithout {
  outline: none;
  text-decoration: none;
}

.slct {
  position: absolute;
  left: 45%;
  bottom: 0;
}

.cases__popular__content {
  display: flex;
  justify-content: center;
}

.casesLeft {
  position: absolute;
  width: 1.35vw;
  left: 10px;
  top: 50%;
  cursor: pointer;
  z-index: 4;
}

.casesRight {
  position: absolute;
  width: 1.35vw;
  right: 10px;
  top: 50%;
  cursor: pointer;
  z-index: 4;
}

.cases_p {
  padding-bottom: 5.83vw;
}

.pl__pr {
  margin-left: 2.86vw;
  margin-right: 2.86vw;
}

.cases__price {
  font-weight: 800;
  font-size: 1.88vw;
  line-height: 2.5vw;
  text-align: center;
  letter-spacing: 0.065em;
  background: linear-gradient(83.8deg, #c62c44 15.62%, #ff0027 91.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 5px 4px 30px #9a1f36, 2px 4px 4px #9a1f36;
}

.cases__desc {
  font-size: 1.35vw;
  text-align: center;
  color: #fff;
}

.cases__popular {
  display: grid;
  margin-top: 3.96vw;
}

.cases__avatar {
  padding-top: 6.35vw;
}

.stock__btn {
  border: 0;
  background: linear-gradient(83.8deg, #c62c44 15.62%, #ff0027 91.62%);
  border-radius: 1.56vw;
  font-weight: 700;
  font-size: 1.46vw;
  color: #fff;
  padding: 0.89vw 1.46vw 0.89vw 1.46vw;
}

.stock__button {
  margin-left: 5vw;
  padding-bottom: 5.21vw;
}

.stock__button2 {
  padding-bottom: 5.21vw;
}

.stock__desc22 {
  font-weight: 700;
  font-size: 2.08vw;
  color: #fff;
}

.stock__desc2 {
  margin-left: 5vw;
  font-weight: 700;
  font-size: 2.08vw;
  color: #fff;
  margin-top: -10.15vw;
}

.stock__left {
  text-align: center;
  margin-top: 12.66vw;
  margin-left: 4.48vw;
}

.stock__left2 {
  margin-top: 4.53vw;
  margin-left: 4.48vw;
}

.stock__right2 {
  margin-top: 11.04vw;
}

.stock {
  -webkit-background-size: cover;
  position: relative;
  height: 50.2vw !important;
}

.inner {
  padding-top: 6.46vw;
  background-image: url("@/assets/img/bgmarket.png");
  background-size: 100vw;
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
}

.cases {
  margin-top: 4.17vw;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
  position: relative;
}

.cases__content {
  position: relative;
  /* padding-top: 12.19vw; */
}

.stock__text {
  text-shadow: 5px 4px 30px #561f8c, 2px 4px 4px #561f8c;
  color: #f7f7f7;
  font-weight: 800;
  font-size: 3.13vw;
}

hr {
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 4px #9028f6;
  filter: blur(1px);
  width: 10.68vw;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .shit {
    margin-top: -2.083vw !important;
  }

  .popular__content {
    height: 26.7vw;
  }

  .item__from {
    font-size: 1.667vw;
  }

  .item__name,
  .item__price {
    font-size: 2.083vw;
  }

  .whywe__items__title {
    font-size: 2.083vw;
    white-space: nowrap;
  }

  .case__name,
  .case__button {
    font-size: 2.083vw;
    white-space: nowrap;
  }

  .header__sec {
    font-size: 2.92vw;
  }

  .header__main {
    font-size: 3.333vw;
  }

  .header__button {
    font-size: 2.083vw;
  }

  .header__button img {
    width: 2.5vw !important;
  }

  .whywe__title img {
    width: 40vw !important;
  }

  .item__block__content {
    height: 31.36vw;
  }

  .weapon__price,
  .weapon__from {
    font-size: 1.75vw;
  }

  .cases__item__desc,
  .cases__item__btn,
  .weapon__name {
    font-size: 2vw;
  }

  .popular__text {
    font-size: 2.55vw;
  }

  .popular__text__content img {
    width: 52vw !important;
  }

  .case__btn {
    font-size: 2vw;
  }

  .case4 {
    margin-bottom: -1vw;
  }

  .mtc {
    margin-top: 1.2vw;
  }

  .case__desc {
    font-size: 2vw;
  }

  .case1,
  .case2,
  .case3,
  .case4 {
    width: 19.67vw !important;
  }

  .case__frame {
    font-size: 2vw;
    margin-left: 10.44vw;
    width: 7.210000000000001vw;
  }

  .cases__content {
    background-size: 150.58vw;
  }

  .cases__href {
    margin-top: 2.2vw;
    font-size: 2vw;
  }

  .cases__sales__text {
    font-size: 2.8vw;
  }

  .cases__sales img {
    width: 4.67vw !important;
  }

  .stock__sec {
    font-family: "Roboto";
    font-size: 1.95vw;
  }

  .stock {
    height: 65.2vw !important;
    padding-top: 10vw;
  }

  .stock__desc,
  .stock__desc2 {
    font-size: 2.67vw;
  }

  .stock__left {
    margin-top: 4.66vw;
  }

  .inner {
    background-size: 105vw;
  }

  .stock__text {
    font-size: 3.93vw;
  }

  .stock__button {
    margin-top: -4vw;
    padding-bottom: 0;
  }

  .stock__buttons {
    margin-left: -0.78vw;
  }

  .stock__btnleft,
  .stock__btnright {
    font-size: 1.7vw;
  }

  .cases__mainimg {
    margin-left: 29vw !important;
  }

  .cases__mainimg2 {
    margin-left: 26vw !important;
  }

  .stock__btn {
    font-size: 2.67vw;
  }

  .stock__img {
    width: 4.08vw !important;
  }

  hr {
    border: 2px solid #ffffff;
  }

  .cases__popular {
    flex-wrap: wrap;
  }

  .cases__popular__content img {
    width: 27vw !important;
  }

  .cases__avatar img {
    width: 7vw !important;
  }

  .cases__desc {
    font-size: 3.2vw;
  }

  .cases__price {
    font-size: 3.5vw;
  }



  .whywe__items__desc {
    font-size: 1.4vw;
  }

  .cases__sales img {
    width: 42vw !important
  }

  .case7t {
    margin-bottom: 1.2vw;
  }

  .stats__count,
  .stats__title {
    margin-bottom: 0;
  }

  .stats__name {
    font-size: 1.5vw;
  }
}

@media screen and (max-width:375px) {
  .item__from {
    font-size: 9px;
  }

  .item__name,
  .item__price {
    font-size: 2.283vw;
    white-space: nowrap;
  }

  .popular__content {
    height: 33.7vw;
  }
}

@media screen and (max-width: 320px) {

  .inner {
    background-size: 101vw;
    padding-top: 11.6vw;
  }

  .stock__buttons {
    margin-left: -1.78vw;
  }

  .stock__left {
    margin-top: -2.34vw;
  }
}
</style>
