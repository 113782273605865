<template>
  <div class="navbar">
    <a href="/" :class="['navbar__btn', { 'active': currentPage == '/' }]">Главная</a>
    <a href="/#FAQ" :class="['navbar__btn']">FAQ</a>
    <router-link to="shop" @click="trackPage('/shop')"
      :class="['navbar__btn', { 'active': currentPage == '/shop' }]">Магазин</router-link>
    <router-link to="cases" @click="trackPage('/cases')"
      :class="['navbar__btn', { 'active': currentPage == '/cases' }]">Кейсы</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    currentPage() {
      return this.$route.path;
    }
  }
}
</script>

<style scoped>
.navbar__btn {
  margin-right: 2.24vw;
  font-size: 1.250vw;
  font-weight: 300;
  color: #fff;
}

.navbar__btn:hover {
  border-bottom: 2px solid rgba(143, 25, 13, 1);
  text-decoration: none;
}

.active {
  /* color:rgba(241, 90, 36, 0.6); */
  background: radial-gradient(114.32% 89.36% at 49.68% 48.94%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%);
  border-radius: 15px;
  padding: 10px 55px;
  /* text-decoration: underline; */
}

@media screen and (max-width: 480px) {
  .active {
    padding: 0.08300000000000018vw 0.90000000000002vw;
  }

  .navbar__btn {
    font-size: 3vw;
  }
}
</style>