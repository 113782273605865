<template>
  <div class="test" v-if="cart_data.length !== 0">
    <div class="cart__products pl__pr" v-for="(item, i) in cart_data" :key="item" :cart_item_data="item"
      @deleteCart="deleteCart(i)">
      <div class="cart__product">
        <img :src="'/data/' + item.item_model_id" alt="" style="width: 8.96vw" class="cart__productIMG"
          v-if="item.game == 'cs'" />
        <img :src="item.img" alt="" style="width: 8.96vw" class="cart__productIMG" v-if="item.game == 'dota'" />
        <div class="product__desc">
          <p class="product__name" v-if="item.game == 'cs'">{{ item.full_type }} {{ item.name }}</p>
          <p class="product__name" v-if="item.game == 'dota'">{{ item.fullName }}</p>
          <p class="product__text">{{ item.rarity }}</p>
          <p class="product__text" v-if="item.game == 'dota'">{{ item.heroes[0] }}</p>
          <p class="product__text" v-if="item.game == 'cs'">{{ item.quality }}</p>
        </div>
        <div class="product__price">
          <p class="product__count font2" v-if="item.game == 'cs'">{{ Math.floor(item.cost) }} ₸</p>
          <p class="product__count font2" v-if="item.game == 'dota'">{{ Math.floor(item.price) }} ₸</p>
        </div>
        <div class="product__close">
          <img src="../assets/img/close.svg" alt="" style="width: 2.6vw; cursor: pointer" @click="deleteCart(i)" />
        </div>
      </div>

    </div>
  </div>
  <div class="pure" v-else>
    <p class="pure__text">
      Корзина пуста
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    cart_data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    ...mapActions(['DeleteFromCart']),
    deleteCart(i) {
      this.DeleteFromCart(i)
    }
  },
  computed: {

  }
};
</script>

<style scoped>
.product__desd {
  margin-left: 1vw;
}

.pure__text {
  color: #fff;
  text-align: center;
  font-size: 1.56vw;
  margin-top: 2.6vw;
}

@media screen and (max-width:480px) {
  .pure__text {
    font-size: 12px !important
  }
}
</style>