<template>
  <div class="main" v-if="IsLogged">
    <div class="logo__cab">
      <img src="@/assets/img/cab.svg" style="width: 21.51vw" alt="" />
    </div>
    <div class="user">
      <div class="user__info ">
        <div class="user__info-group">
          <img src="@/assets/img/fullsavatar.svg" alt="" style="width: 6.51vw" class="user__ava" />
          <div class="user__margin" style="margin-left: 2.08vw">
            <p class="user__info-name mb-0">{{ userName }}</p>
            <a href="#" class="steam__href">
              <img src="@/assets/img/steamico.svg" style="width: 1.46vw" class="steam__ico" />
              {{ userName }}
            </a>
          </div>
        </div>
      </div>

    </div>
    <div class="user__bg">
      <div class="user__settings">
        <hr />
        <div class="user__links row">
          <div class="col">
            <div class="user__password pt-pb">
              <div class="user__credits-money">
                <img src="@/assets/img/walletlc.svg" style="width: 2.083vw" alt="" />
                <p class="user__count mb-0 font2" style="margin-left: 1vw">{{ userBalance }} ₸</p>
              </div>
              <div class="d-flex money__buttons">
                <button class="moneyadd mr-4" data-toggle="modal" data-target="#Money">Пополнить баланс</button>
                <button class="output" data-toggle="modal" data-target="#OutputMoney">Вывести средства</button>
              </div>
              <div class="user__password-input">
                <label for="current__password" class="title">Текущий пароль</label>
                <input type="password" name="current__password" id="current__password" class="user-input" />
              </div>

              <div class="user__password-input">
                <label for="new__password" class="title">Новый пароль</label>
                <input type="password" name="new__password" id="new__password" class="user-input" />
              </div>

              <div>
                <label for="accept__password" class="title">Подвердите пароль</label>
                <input type="password" name="accept__password" id="accept__password" class="user-input" />
              </div>
              <button class="user__btn">Сменить пароль</button>
            </div>
          </div>
          <div class="user__links-trade pt-pb col">
            <div>
              <p class="title">Трейд-ссылка</p>
              <a href="#" class="howget">Где получить?</a>
            </div>
            <div>
              <input type="text" placeholder="https://steamcommunity.com/tradeoffer/new/?partner=969138412&token=NJff8Nnr"
                name="trade" class="user-input" />
              <img src="@/assets/img/chain.svg" style="width: 1.67vw" class="chain" />
            </div>
            <div class="user__links-email pt-pb">
              <div class="user__password-input" style="flex-direction: row">
                <p class="title">E-mail</p>
                <img src="@/assets/img/pen.svg" style="width: 1.04vw" alt="#" />
              </div>
              <input type="email" placeholder="alex_krrr777@csmarket.com" class="user-input" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="auth" v-else>Авторизуйтесь</p>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      isType: 3,
      userName: null,
      userBalance: null,
      openId: null,
      balanceReal: [],
    }
  },
  computed: {
    ...mapGetters(["IsLogged", "USER_NAME"]),
  },
  mounted() {

    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);
      axios({
        method: 'get',
        url: `https://cs-market.kg/api/get_user_openid/?open_id=${decoded.open_id}&site_name=nomadgames`,
      })
        .then((response) => {
          if (response.data.jwt_token) {
            console.log(response.data)
            this.balanceReal = response.data.transactions
            localStorage.setItem("userName", response.data.jwt_token);
          }
          else {
            return
          }
        })
        .catch((error) => {

          console.error(error);
        })
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      const path = "https://cs-market.kg/api/refresh_token";
      axios
        .post(path, { email: decoded.email, password: decoded.password, site_name: 'nomadgames' })
        .then((res) => {
          console.log(res.data)
          this.balanceReal = res.data.transactions
          localStorage.setItem("userName", res.data.jwt_token);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.name
      this.userBalance = decoded.balance
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.email
      this.userBalance = decoded.balance
    }
  },
};
</script>

<style scoped>
.money__buttons {
  margin-bottom: 5.208vw;
}

.moneyadd {
  background: rgba(143, 25, 13, 0.4);
  border: 1px solid #8F190D;
  border-radius: 10px;
  padding: 0.99vw 2.396vw 0.99vw 2.396vw;
  font-weight: 400;
  font-size: 1.042vw;
  color: #fff;
  white-space: nowrap;
}

.output {
  border: 1px solid #8F190D;
  border-radius: 10px;
  background: transparent;
  padding: 0.99vw 2.396vw 0.99vw 2.396vw;
  font-weight: 400;
  font-size: 1.042vw;
  color: #C1121C;
  white-space: nowrap;
}

.logo__cab {
  display: flex;
  justify-content: center;
}

.auth {
  text-align: center;
  font-size: 6vw;
  color: #fff;
  padding-top: 9vw;
}

.howget {
  font-weight: 400;
  font-size: 0.83vw;
  text-decoration: underline;
  color: #fff;
  margin-right: 1.4vw;
}

.chain {
  position: absolute;
  left: -2.2vw;
}

::placeholder {
  font-size: 1.04vw;
}

.main {
  padding-top: 9.38vw;
}

.lc {
  font-weight: 900;
  font-size: 2.08vw;
  text-align: center;
  color: #fff;
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.user__info {
  display: flex;
  align-items: center;
}

.user__info img {
  max-width: 8.33vw;
}

.user__info-group {
  display: flex;
  padding-left: 2rem;
  padding-bottom: 2rem;
  align-items: center;

}

.user__info-group a {
  padding: 0.5rem;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
  opacity: 0.5;
  line-height: 1.46vw;
}

.user__info-name {
  padding: 1rem 0.7rem;
  font-weight: 600;
  font-size: 1.67vw;
  line-height: 1.67vw;
  color: #fff;
}

.user__credits {
  height: 8.33vw;
  align-items: center;
  flex-wrap: wrap;
}

.user__credits-money {
  display: flex;
  min-width: 8.33vw;
  align-items: center;
  margin: 0 2rem;
}

.user__credits-money p {
  font-weight: 500;
  font-size: 2.08vw;
  line-height: 1.25vw;
  color: #fff;
}

.user__credits-buttons {
  display: flex;
}

.user__btn1 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #FFFFFF;
  background: rgba(143, 25, 13, 0.4);
  border: 1px solid #8F190D;
  border-radius: 10px;
  padding: 0.938vw 9.219vw 0.938vw 9.219vw;
}

.user__btn {
  font-weight: 400;
  font-size: 1.042vw;
  color: #FFFFFF;
  background: rgba(143, 25, 13, 0.4);
  border: 1px solid #8F190D;
  border-radius: 10px;
  padding: 0.938vw 9.219vw 0.938vw 9.219vw;
  white-space: nowrap;
}

.user__credits-buttons button {
  min-width: 14.58vw;
  box-sizing: border-box;
}

hr {
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.1;
}

.user__settings {
  margin: 0 2rem;
  padding-left: 5.21vw;
  padding-right: 5.21vw;

}

.user__bg {
  background: url("@/assets/img/lcbg.png") no-repeat;
  background-position-x: 39.063vw;
  background-position-y: 8.438vw;
  background-size: 60.104vw;
}

.user__links {
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
}

.user__links-trade {
  padding: 1rem 0;
  width: 50%;
  gap: 1rem;
}

.user__links-trade>div {
  display: flex;
  justify-content: space-between;
}

.user-input {
  height: 3.13vw;
  width: 100%;
  border: 0;
  padding: 5px;
  color: rgba(255, 255, 255, 0.3);
  background: radial-gradient(82.39% 82.39% at 50% 50%,
      rgba(30, 29, 33, 0.4) 0%,
      rgba(60, 59, 63, 0.4) 100%);
  border: 1px solid #6d6d6d;
  box-sizing: border-box;
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: #fff;
}

.pt-pb {
  padding: 2rem 0;
}

.user__links-trade img {
  position: relative;
  right: 0;
}

.user__links-email {
  display: flex;
  flex-direction: column;
  width: 18.23vw;
  gap: 1rem;
}

.user__links-email div {
  display: flex;
  justify-content: space-between;
}

.user__password {
  display: flex;
  width: 26.04vw;
  flex-direction: column;
  gap: 1em;
}

.user__password-input {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.pd-1 {
  padding: 0.5em 0;
}

@media screen and (max-width: 480px) {

  .user__settings {
    padding-left: 0.20999999999999996vw;
    padding-right: 0.21000000000000085vw;
  }

  .money__buttons {
    flex-direction: column;
  }

  .user__credits-money img {
    width: 20px !important
  }

  .logo__cab img {
    width: 30vw !important;
  }

  .user__btn {
    padding: 0.938vw 9.219vw 0.938vw 5.218vw;
  }

  .moneyadd {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .output,
  .moneyadd {
    font-size: 7px;
  }

  .user__bg {
    background-position-x: 39.063vw;
    background-position-y: 63.438vw;
  }

  .main {
    padding-top: 15.38vw;
  }

  .user__count {
    font-size: 4vw !important;
    margin-bottom: 7vw;
  }

  .user__margin {
    margin-left: 2vw !important;
  }

  .steam__ico {
    width: 4vw !important;
  }

  .user__btn img {
    width: 3.5vw !important;
  }

  .user__btn,
  .user__btn1 {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  .user__info-name,
  .steam__href,
  .title,
  .howget {
    font-size: 2vw !important;
  }

  .howget {
    margin-top: -1vw;
  }

  .user__password-input img {
    width: 3vw !important;
    margin-top: -5vw;
  }

  .user-input {
    height: 6vw;
  }

  .user__info-name {
    margin-left: -2vw;
  }

  .user__info img {
    max-width: 13vw;
  }

  .user__info-group {
    padding-bottom: 0;
    padding-left: 1rem;
  }

  .user__ava {
    width: 20vw !important;
  }
}

@media screen and (max-width:375px) {
  .user__bg {
    background-position-x: 39.063vw;
    background-position-y: 83.438vw;
  }
}
</style>
