<template>
  <div class="container-fluid cases_page">
    <div class="row">
      <div class="col-12" style="padding-top: 40px;">
        <div class="casses__section position-relative">
          <img src="@/assets/img/casesMan.png" class="cases__manLeft" alt="">
          <img src="@/assets/img/casesManRight.png" class="cases__manRight" alt="">
          <span class="casses__title">
            <div :class="[{ 'blockTitleSel': activeTab == 'cs' }, { 'blockTitle': activeTab == 'dota' }, 'mr-4']"
              :style="{ 'background-image': 'url(/img/backTitle1' + activeTab + '.png)' }" @click="activeTab = 'cs'">
              Популярные кейсы CS:GO
            </div>
            <div :class="[{ 'blockTitleSel': activeTab == 'dota' }, { 'blockTitle': activeTab == 'cs' }]"
              :style="{ 'background-image': 'url(/img/dotaTitle' + activeTab + '.png)' }" @click="activeTab = 'dota'">
              Популярные DOTA 2
            </div>
          </span>
          <!-- CS -->
          <div class="casses__row cont__pd" @click="tryToBuy" v-if="activeTab == 'cs'">
            <div class="casses__card">
              <img src="../assets/img/GammaCase.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Gamma”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 3 500 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/RestrictedCase.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Запретная зона”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 3 700 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/InfernoCase.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Huntsman”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 3 000 ₸
                </div>
              </div>
            </div>
          </div>

          <div class="casses__row cases__second__row cont__pd" @click="tryToBuy" v-if="activeTab == 'cs'">
            <div class="casses__card">
              <img src="../assets/img/BravoCase.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Bravo”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 4 390 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/RevolverCase.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Revolver”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 2 590 ₸
                </div>
              </div>
            </div>
          </div>

          <!-- DOTA -->
          <div class="casses__row cont__pd" @click="tryToBuy" v-if="activeTab == 'dota'">
            <div class="casses__card">
              <img src="../assets/img/dotacase1.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Pudge”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 2000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotacase2.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Phantom Assasin”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 1600 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotacase3.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Lion”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 1400 ₸
                </div>
              </div>
            </div>
          </div>

          <div class="casses__row cases__second__row cont__pd" @click="tryToBuy" v-if="activeTab == 'dota'">
            <div class="casses__card">
              <img src="../assets/img/dotacase4.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Invoker”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 1800 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotacase5.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Кейс “Sniper”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 1200 ₸
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="casses__section cont__pdbig">
          <span class="casses__title">
            <div :class="['blockTitleSel']" :style="{ 'background-image': 'url(/img/backTitle1cs.png)' }">
              Акции и скидки
            </div>
          </span>
          <div class="row mt-4">
            <div class="col-12 col-md-6" v-if="activeTab == 'cs'">
              <div class="row">
                <div class="col-6">
                  <div class="casses__card" @click="tryToBuy">
                    <div class="cases__sale">
                      -5%
                    </div>
                    <img src="../assets/img/casesDeagle.png" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Desert Eagle
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 5 690 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card h-100" @click="tryToBuy">
                    <div class="cases__sale">
                      -10%
                    </div>
                    <img src="../assets/img/casesAwp.png" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        AWP
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 15 990 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card h-100" @click="tryToBuy">
                    <div class="cases__sale">
                      -5%
                    </div>
                    <img src="../assets/img/casesRev.png" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        R8 Revolver
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 7 390 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card" @click="tryToBuy">
                    <div class="cases__sale">
                      -10%
                    </div>
                    <img src="../assets/img/casesPistol.png" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Glock-18
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 3 590 ₸
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6" v-if="activeTab == 'dota'">
              <div class="row">
                <div class="col-6">
                  <div class="casses__card" @click="tryToBuy">
                    <div class="cases__sale">
                      -5%
                    </div>
                    <img src="../assets/img/dotaSale1.webp" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Demon Eater
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 10 000 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card h-100" @click="tryToBuy">
                    <div class="cases__sale">
                      -10%
                    </div>
                    <img src="../assets/img/dotaSale2.webp" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Magus Apex
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 1 000 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card h-100" @click="tryToBuy">
                    <div class="cases__sale">
                      -5%
                    </div>
                    <img src="../assets/img/dotaSale3.webp" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Hunter's Hoard
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 2 600 ₸
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="casses__card" @click="tryToBuy">
                    <div class="cases__sale">
                      -10%
                    </div>
                    <img src="../assets/img/dotaSale4.webp" alt="" class="casses__img">
                    <div class="card__footer">
                      <div class="casses__name">
                        Righteous Thunderbolt
                      </div>
                      <div class="casses__btn font2">
                        <span>от</span>&nbsp; 3 200 ₸
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6" v-if="activeTab == 'cs'" @click="tryToBuy">
              <div class="hotSale__Block">
                <div class="hotSale__header">
                  <div class="hotSale__headerLeft">
                    <span class="hotSale__headerLeft__title">
                      ТОП ПРЕДЛОЖЕНИЕ!
                    </span>
                    <span class="hotSale__headerLeft__sale">
                      -{{ sale }}%
                    </span>
                  </div>
                  <div class="hotSale__headerRight">
                    {{ endTime }}
                  </div>
                </div>
                <div class="hotSale__body">
                  <img :src="'/data/' + csSale.item_model_id" class="item__img" alt="">
                </div>
                <div class="hotSale__footer">
                  <div class="hotSale__name">
                    {{ csSale.full_type }} {{ csSale.name }}
                  </div>
                  <div class="hotSale__price">
                    от {{ Math.floor(csSale.cost) }} ₸
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6" v-if="activeTab == 'dota'">
              <div class="hotSale__Block">
                <div class="hotSale__header">
                  <div class="hotSale__headerLeft">
                    <span class="hotSale__headerLeft__title">
                      ТОП ПРЕДЛОЖЕНИЕ!
                    </span>
                    <span class="hotSale__headerLeft__sale">
                      -{{ sale }}%
                    </span>
                  </div>
                  <div class="hotSale__headerRight">
                    {{ endTime }}
                  </div>
                </div>
                <div class="hotSale__body">
                  <img :src="'/data/' + csSale.item_model_id" class="item__img" alt="">
                </div>
                <div class="hotSale__footer">
                  <div class="hotSale__name">
                    {{ csSale.full_type }} {{ csSale.name }}
                  </div>
                  <div class="hotSale__price">
                    от {{ Math.floor(csSale.cost) }} ₸
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="casses__section cont__pd">
          <span class="casses__title">
            <div :class="['blockTitleSel']" :style="{ 'background-image': 'url(/img/backTitle1cs.png)' }">
              Популярные предметы
            </div>
          </span>
          <div class="casses__row" @click="tryToBuy" v-if="activeTab == 'cs'">
            <div class="casses__card">
              <img src="../assets/img/BestP20001.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  P2000 “Imperial Dragon”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 4 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/BestAwp.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  AWP “Asiimov”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 40 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/BestKarambit.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Karambit “Fade”
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 500 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/BestM4a4.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  M4A4 "Howl"
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 110 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/BestAug.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Aug "Akihabara"
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 84 000 ₸
                </div>
              </div>
            </div>
          </div>
          <div class="casses__row" @click="tryToBuy" v-if="activeTab == 'dota'">
            <div class="casses__card">
              <img src="../assets/img/dotaItem1.webp" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Arms of Desolation
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 1 200 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotaItem2.webp" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Feast of Abscession
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 12 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotaItem3.webp" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Inscribed Sizzling Charge
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 120 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotaItem4.png" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Wings of Royal Ascension
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 48 000 ₸
                </div>
              </div>
            </div>

            <div class="casses__card">
              <img src="../assets/img/dotaItem5.webp" alt="" class="casses__img">
              <div class="card__footer">
                <div class="casses__name casses__namePop">
                  Hakobi and Tenneko
                </div>
                <div class="casses__btnR">
                  <span>Купить за</span>&nbsp; 42 000 ₸
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import getSaleCS from '@/assets/js/csforsale.js'
export default {
  data() {
    return {
      cases: [

      ],
      newcases: [],
      salecases: [

      ],
      activeTab: "cs",
      start: new Date(),
      timer: null,
      csSaleTemp: {},
    };
  },
  methods: {
    getCases() {
      const path = "https://cs-market.kg/api/cases";
      axios
        .get(path)
        .then((res) => {
          // for(let i=31; i<36;i++){
          //     this.newcases.push(res.data[i])
          // }
          // for(let i=91; i<96;i++){
          //     this.cases.push(res.data[i])
          // }
          // for(let i=51; i<56;i++){
          //     this.salecases.push(res.data[i])
          // }
          // this.cases = res.data;
          console.log(res);
          console.log(this.cases);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.start = new Date()
      }, 1000)
    },

  },
  mounted() {
    this.startTimer();
    this.getCases();
    let csarr = getSaleCS();
    let date = (new Date()).getDate()
    this.csSaleTemp = csarr[date]
    console.log('asddasd');
    console.log(this.csarr);
    if (window.localStorage.getItem('game')) {
      this.activeTab = window.localStorage.getItem('game')
    }
  },
  computed: {
    endTime() {
      // let start = new Date();
      let year = this.start.getFullYear()
      let month = this.start.getMonth()
      let day = this.start.getDate()
      let end = new Date(year, month, day, 23, 59, 59)
      let difference = end - this.start
      let minutes = 0;
      let seconds = 0;
      let hour = Math.floor(difference / (1000 * 60 * 60))
      minutes = Math.floor((difference / (1000 * 60 * 60) - hour) * 60)
      seconds = Math.floor((((difference / (1000 * 60 * 60) - hour) * 60 - minutes) * 60))
      let diferenceString = Math.floor(difference / (1000 * 60 * 60)) + ':' + minutes + ':' + seconds
      return diferenceString
    },
    sale() {
      let now = new Date();
      let date = now.getDate()
      if (date % 3 == 0 && date % 2 == 0) {
        return 45
      }
      else if (date % 3 == 0) {
        return 40
      }
      else if (date % 2 == 0) {
        return 30
      }
      else return 25
    },
    csSale() {
      if (this.csSaleTemp) {
        return this.csSaleTemp
      }
      return {
        name: 'Вой',
        full_type: 'M4A4',
        coast: 100000,
        item_model_id: 1,
      }
    }
  },

};
</script>

<style scoped>
.casses__card {
  cursor: pointer;
}

.cases__sale {
  background: rgba(143, 25, 13, 0.4);
  border: 1px solid rgba(143, 25, 13, 1);
  padding: 4px 8px;
  margin-left: auto;
  margin-right: 10px;
  border-radius: 20px;
  font-family: 'Josefin Sans';
}

.casses__btn span {
  font-size: 1.042vw;
  color: rgba(255, 255, 255, 0.3);
}

.casses__namePop {
  text-align: center;
  margin-top: 0.5vw;
}

.casses__btnR {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif !important;
  background: none;
  border-bottom: 2px solid #8F190D;
  box-sizing: border-box;
  /* border-radius: 5px; */
  color: #fff;
  padding: 0.34vw 1.02vw 0.34vw 1.02vw;
  font-weight: 400;
  font-size: 1vw;
  margin-top: 0.4vw;
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 3.13vw; */
  /* -webkit-clip-path: polygon(0 10%, 100% 0, 100% -90%, 0 100%);
  clip-path: polygon(10% 0, 0 100%, 90% 100%, 100% 0); */
}






.cases_page {
  /* background-color: #1A1814; */
  /* background: no-repeat right/80% url(@/assets/img/SolderBack.png) #141721; */
  padding-bottom: 20px;
}

.casses__section {
  margin-top: 4.167vw;
  margin-bottom: 4vw;
}

.casses__title {
  font-weight: 900;
  font-size: 1.667vw;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.casses__row {
  display: flex;
  justify-content: space-between;
  margin-top: 2.604vw;
}

.casses__card {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.casses__card:hover .casses__btnR {
  background: radial-gradient(114.32% 89.36% at 49.68% 48.94%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%);
  border-radius: 15px;
  /* background: radial-gradient(82.39% 82.39% at 50% 50%, rgba(30, 29, 33, 0.4) 0%, rgba(60, 59, 63, 0.4) 100%) */
}

.casses__img {
  width: 12.323vw;
  margin-left: auto;
  margin-right: auto;
}

.casses__name {
  font-weight: 900;
  font-size: 1.146vw;
  margin-top: 2.204vw;
}

.casses__namePop {
  text-align: center;
  margin-top: 0.5vw;
}

.casses__btn {
  font-size: 1.250vw;
  color: white;
  margin-top: 0.304vw;
  font-weight: 400;
}

/* LIVE */
.live__card {
  width: 80%;
  padding-top: 1.042vw;
  display: flex;
  flex-direction: column;
  background: radial-gradient(#1E1D21, #3C3B3F);
  border-left: 4px solid #2C6DF5;
}

.live__img {
  width: 7.240vw;
  margin-left: auto;
  margin-right: auto;
}

.live__bottom {
  padding-left: 14px;
  padding-right: 14px;
}

.live__bottomgroup {
  display: flex;
  justify-content: space-between;
}

.live__name {
  color: white;
  font-size: 1.042vw;
  font-weight: 900;
}

.live__quality {
  color: #FFFFFF4D;
  font-size: 1.042vw;
}

.live__price {
  font-weight: 700;
  font-size: 1.042vw;
  color: #2C6DF5;
}

@media screen and (max-width:480px) {
  .casses__title {
    font-size: 2.667vw;
  }

  .casses__title img {
    width: 50vw;
  }

  .cases__sale {
    font-size: 12px;
    padding: 2px 4px;
  }

  .casses__img {
    width: 25.323vw;
  }

  .casses__row {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .casses__namePop {
    width: 30vw;
  }

  .casses__card {
    align-items: center;
  }

  .casses__btnR {
    font-size: 3.04vw;
  }

  .casses__name {
    font-size: 3vw;
  }

  .casses__btn {
    font-size: 3.25vw;
  }

  /* HHOT */
  .hotSale__headerLeft__title {
    font-size: 2.85vw;
  }

  .hotSale__headerRight,
  .hotSale__headerLeft__sale,
  .hotSale__name {
    font-size: 3.5vw;
  }

  .hotSale__price {
    font-size: 4vw;
  }

}
</style>

<style scoped>
.blockTitle {
  /* background-image: url('./bck/img/backTitle.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 11px 19px;
  cursor: pointer;
}

.blockTitleSel {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 16px 19px;
  cursor: pointer;
}

.cases__second__row {
  justify-content: space-evenly;
  margin-top: 1vw;
}

.cont__pd {
  padding-left: 5vw;
  padding-right: 5vw;
}

.cont__pdbig {
  padding-left: 10vw;
  padding-right: 10vw;
}

.cases__manLeft {
  position: absolute;
  left: 0;
  top: 5vw;
  z-index: -1;
  width: 56.41vw;
}

.cases__manRight {
  position: absolute;
  right: 0;
  bottom: 0vw;
  z-index: -1;
  width: 30.78vw;
}

.hotSale__Block {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 100%;
  background: radial-gradient(114.32% 89.36% at 49.68% 48.94%, rgba(143, 25, 13, 0) 0%, rgba(143, 25, 13, 0.7) 100%);
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  padding: 1.5vw;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hotSale__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.hotSale__headerLeft {
  display: flex;
  flex-direction: column;
}

.hotSale__headerLeft__title {
  font-weight: 500;
  font-size: 0.85vw;
  color: #FFFFFF;
}

.hotSale__headerLeft__sale {
  font-weight: 500;
  font-size: 2.6vw;
}

.hotSale__headerRight {
  font-weight: 500;
  font-size: 1.5vw;
  color: #FFFFFF;
  line-height: 1.5vw;
}

.hotSale__body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotSale__Block img {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.hotSale__name {
  font-weight: 700;
  font-size: 1.5vw;
  color: #FFFFFF;
}

.hotSale__price {
  font-weight: 400;
  font-size: 1.25vw;
}

@media screen and (max-width: 480px) {
  .hotSale__headerLeft__title {
    font-size: 2.85vw;
  }

  .hotSale__headerRight,
  .hotSale__headerLeft__sale,
  .hotSale__name {
    font-size: 3.5vw;
  }

  .hotSale__price {
    font-size: 4vw;
  }

}</style>