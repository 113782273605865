export default function getSaleCS(){
    return [
        {
            "_id": {
                "$oid": "62530b58ae66f9136a4328d7"
            },
            "cost": 101594.3903,
            "full_type": "Glock-18",
            "gun_float": "0.06336965",
            "item_model_id": "images/pistols/glock-18/14.png",
            "name": "Gamma Doppler Emerald",
            "pattern": "916",
            "quality": "FN",
            "rarity": "Тайное",
            "type": "glock-18"
        },
        {
            "_id": {
                "$oid": "62531d8aae66f9136a432b21"
            },
            "cost": 42827.858,
            "full_type": "Souvenir MAG-7",
            "gun_float": "0.01176184",
            "item_model_id": "images/shotguns/mag-7/9.png",
            "name": "Core Breach",
            "pattern": "742",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "mag-7"
        },
        {
            "_id": {
                "$oid": "62531475ae66f9136a432a5b"
            },
            "cost": 22043.6026,
            "full_type": "Galil AR",
            "gun_float": "0.06993953",
            "item_model_id": "images/assault-rifles/galil-ar/7.png",
            "name": "Orange DDPAT",
            "pattern": "326",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "galil-ar"
        },
        {
            "_id": {
                "$oid": "62531d0fae66f9136a432b06"
            },
            "cost": 23804.7516,
            "full_type": "StatTrak™ SSG 08",
            "gun_float": "0.02420955",
            "item_model_id": "images/sniper-rifles/ssg-08/7.png",
            "name": "Big Iron",
            "pattern": "755",
            "quality": "FN",
            "rarity": "Засекреченное",
            "type": "ssg-08"
        },
        {
            "_id": {
                "$oid": "625307c293f8e873b266c261"
            },
            "cost": 21797.6057,
            "full_type": "StatTrak™ CZ75-Auto",
            "gun_float": "0.39804452",
            "item_model_id": "images/pistols/cz75-auto/22.png",
            "name": "Victoria",
            "pattern": "99",
            "quality": "WW",
            "rarity": "Тайное",
            "type": "cz75-auto"
        },
        {
            "_id": {
                "$oid": "62530ecbae66f9136a432936"
            },
            "cost": 21571.3474,
            "full_type": "StatTrak™ Tec-9",
            "gun_float": "0.04456593",
            "item_model_id": "images/pistols/tec-9/10.png",
            "name": "Fuel Injector",
            "pattern": "844",
            "quality": "FN",
            "rarity": "Засекреченное",
            "type": "tec-9"
        },
        {
            "_id": {
                "$oid": "62531ecdae66f9136a432b65"
            },
            "cost": 20066.0033,
            "full_type": "XM1014",
            "gun_float": "0.06876522",
            "item_model_id": "images/shotguns/xm1014/2.png",
            "name": "Fallout Warning",
            "pattern": "642",
            "quality": "FN",
            "rarity": "Промышленное",
            "type": "xm1014"
        },
        {
            "_id": {
                "$oid": "62530a3eae66f9136a4328c5"
            },
            "cost": 19992.2594,
            "full_type": "StatTrak™ Five-SeveN",
            "gun_float": "0.03423152",
            "item_model_id": "images/pistols/five-seven/21.png",
            "name": "Case Hardened",
            "pattern": "704",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "five-seven"
        },
        {
            "_id": {
                "$oid": "62531140ae66f9136a4329b9"
            },
            "cost": 18634.4644,
            "full_type": "MP9",
            "gun_float": "0.08645388",
            "item_model_id": "images/smgs/mp9/18.png",
            "name": "Starlight Protector",
            "pattern": "959",
            "quality": "MW",
            "rarity": "Тайное",
            "type": "mp9"
        },
        {
            "_id": {
                "$oid": "62531d13ae66f9136a432b07"
            },
            "cost": 18418.0141,
            "full_type": "StatTrak™ SSG 08",
            "gun_float": "0.14704199",
            "item_model_id": "images/sniper-rifles/ssg-08/8.png",
            "name": "Dragonfire",
            "pattern": "733",
            "quality": "MW",
            "rarity": "Тайное",
            "type": "ssg-08"
        },
        {
            "_id": {
                "$oid": "62531defae66f9136a432b37"
            },
            "cost": 17872.015,
            "full_type": "Nova",
            "gun_float": "0.06586937",
            "item_model_id": "images/shotguns/nova/6.png",
            "name": "Bloomstick",
            "pattern": "323",
            "quality": "FN",
            "rarity": "Засекреченное",
            "type": "nova"
        },
        {
            "_id": {
                "$oid": "62531c3eae66f9136a432aec"
            },
            "cost": 16037.0608,
            "full_type": "SCAR-20",
            "gun_float": "0.07587897",
            "item_model_id": "images/sniper-rifles/scar-20/4.png",
            "name": "Emerald",
            "pattern": "829",
            "quality": "MW",
            "rarity": "Запрещённое",
            "type": "scar-20"
        },
        {
            "_id": {
                "$oid": "62530facae66f9136a432965"
            },
            "cost": 14915.4547,
            "full_type": "MAC-10",
            "gun_float": "0.43426635",
            "item_model_id": "images/smgs/mac-10/7.png",
            "name": "Gold Brick",
            "pattern": "128",
            "quality": "WW",
            "rarity": "Запрещённое",
            "type": "mac-10"
        },
        {
            "_id": {
                "$oid": "625313f8ae66f9136a432a40"
            },
            "cost": 14448.1035,
            "full_type": "FAMAS",
            "gun_float": "0.11775474",
            "item_model_id": "images/assault-rifles/famas/5.png",
            "name": "Afterimage",
            "pattern": "487",
            "quality": "MW",
            "rarity": "Засекреченное",
            "type": "famas"
        },
        {
            "_id": {
                "$oid": "62531144ae66f9136a4329ba"
            },
            "cost": 13651.203499999998,
            "full_type": "StatTrak™ MP9",
            "gun_float": "0.30250778",
            "item_model_id": "images/smgs/mp9/19.png",
            "name": "Starlight Protector",
            "pattern": "503",
            "quality": "FT",
            "rarity": "Тайное",
            "type": "mp9"
        },
        {
            "_id": {
                "$oid": "6253210aae66f9136a432b97"
            },
            "cost": 11565.4097,
            "full_type": "StatTrak™ Negev",
            "gun_float": "0.06766476",
            "item_model_id": "images/machineguns/negev/2.png",
            "name": "Power Loader",
            "pattern": "833",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "negev"
        },
        {
            "_id": {
                "$oid": "625309a9ae66f9136a4328af"
            },
            "cost": 11235.7996,
            "full_type": "Souvenir Dual Berettas",
            "gun_float": "0.44575867",
            "item_model_id": "images/pistols/dual-berettas/24.png",
            "name": "Twin Turbo",
            "pattern": "860",
            "quality": "WW",
            "rarity": "Засекреченное",
            "type": "dual-berettas"
        },
        {
            "_id": {
                "$oid": "62530edfae66f9136a43293b"
            },
            "cost": 10340.451799999999,
            "full_type": "Souvenir Tec-9",
            "gun_float": "0.67302370",
            "item_model_id": "images/pistols/tec-9/15.png",
            "name": "Remote Control",
            "pattern": "688",
            "quality": "BS",
            "rarity": "Засекреченное",
            "type": "tec-9"
        },
        {
            "_id": {
                "$oid": "625312a5ae66f9136a4329ff"
            },
            "cost": 6586.991499999999,
            "full_type": "UMP-45",
            "gun_float": "0.02701760",
            "item_model_id": "images/smgs/ump-45/13.png",
            "name": "Blaze",
            "pattern": "957",
            "quality": "FN",
            "rarity": "Армейское качество",
            "type": "ump-45"
        },
        {
            "_id": {
                "$oid": "62530b58ae66f9136a4328d7"
            },
            "cost": 101594.3903,
            "full_type": "Glock-18",
            "gun_float": "0.06336965",
            "item_model_id": "images/pistols/glock-18/14.png",
            "name": "Gamma Doppler Emerald",
            "pattern": "916",
            "quality": "FN",
            "rarity": "Тайное",
            "type": "glock-18"
        },
        {
            "_id": {
                "$oid": "62531d8aae66f9136a432b21"
            },
            "cost": 42827.858,
            "full_type": "Souvenir MAG-7",
            "gun_float": "0.01176184",
            "item_model_id": "images/shotguns/mag-7/9.png",
            "name": "Core Breach",
            "pattern": "742",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "mag-7"
        },
        {
            "_id": {
                "$oid": "62531475ae66f9136a432a5b"
            },
            "cost": 22043.6026,
            "full_type": "Galil AR",
            "gun_float": "0.06993953",
            "item_model_id": "images/assault-rifles/galil-ar/7.png",
            "name": "Orange DDPAT",
            "pattern": "326",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "galil-ar"
        },
        {
            "_id": {
                "$oid": "62531d0fae66f9136a432b06"
            },
            "cost": 23804.7516,
            "full_type": "StatTrak™ SSG 08",
            "gun_float": "0.02420955",
            "item_model_id": "images/sniper-rifles/ssg-08/7.png",
            "name": "Big Iron",
            "pattern": "755",
            "quality": "FN",
            "rarity": "Засекреченное",
            "type": "ssg-08"
        },
        {
            "_id": {
                "$oid": "625307c293f8e873b266c261"
            },
            "cost": 21797.6057,
            "full_type": "StatTrak™ CZ75-Auto",
            "gun_float": "0.39804452",
            "item_model_id": "images/pistols/cz75-auto/22.png",
            "name": "Victoria",
            "pattern": "99",
            "quality": "WW",
            "rarity": "Тайное",
            "type": "cz75-auto"
        },
        {
            "_id": {
                "$oid": "62530ecbae66f9136a432936"
            },
            "cost": 21571.3474,
            "full_type": "StatTrak™ Tec-9",
            "gun_float": "0.04456593",
            "item_model_id": "images/pistols/tec-9/10.png",
            "name": "Fuel Injector",
            "pattern": "844",
            "quality": "FN",
            "rarity": "Засекреченное",
            "type": "tec-9"
        },
        {
            "_id": {
                "$oid": "62531ecdae66f9136a432b65"
            },
            "cost": 20066.0033,
            "full_type": "XM1014",
            "gun_float": "0.06876522",
            "item_model_id": "images/shotguns/xm1014/2.png",
            "name": "Fallout Warning",
            "pattern": "642",
            "quality": "FN",
            "rarity": "Промышленное",
            "type": "xm1014"
        },
        {
            "_id": {
                "$oid": "62530a3eae66f9136a4328c5"
            },
            "cost": 19992.2594,
            "full_type": "StatTrak™ Five-SeveN",
            "gun_float": "0.03423152",
            "item_model_id": "images/pistols/five-seven/21.png",
            "name": "Case Hardened",
            "pattern": "704",
            "quality": "FN",
            "rarity": "Запрещённое",
            "type": "five-seven"
        },
        {
            "_id": {
                "$oid": "62531140ae66f9136a4329b9"
            },
            "cost": 18634.4644,
            "full_type": "MP9",
            "gun_float": "0.08645388",
            "item_model_id": "images/smgs/mp9/18.png",
            "name": "Starlight Protector",
            "pattern": "959",
            "quality": "MW",
            "rarity": "Тайное",
            "type": "mp9"
        },
        {
            "_id": {
                "$oid": "62531d13ae66f9136a432b07"
            },
            "cost": 18418.0141,
            "full_type": "StatTrak™ SSG 08",
            "gun_float": "0.14704199",
            "item_model_id": "images/sniper-rifles/ssg-08/8.png",
            "name": "Dragonfire",
            "pattern": "733",
            "quality": "MW",
            "rarity": "Тайное",
            "type": "ssg-08"
        },
    ];
}