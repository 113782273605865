import { createApp } from 'vue'
import './assets/style/fonts.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Header from './components/Header'
import Footer from './components/CsFooter'
import NavBar from './components/NavBar'
import Shop from './views/Shop'
import Cases from './views/Cases'
import FAQ from './components/FAQ'
import HowSteps from './components/HowSteps'
import Account from './views/Account'
import CartPage from './views/CartPage'
import OpsModal from './components/OpsModal'
import LogReg from './components/LogReg'
import RegModal from './components/RegModal'
import MoneyModal from './components/MoneyModal'
import PaymentError from './components/PaymentError'
import OutputModal from './components/OutputModal'
import VueGtag from "vue-gtag-next";

import {mapGetters} from 'vuex'

const Mixin = {
    data(){
        return {
           
        }
    },
    methods: {
        tryToBuy(){
            if(this.IsLogged){
                $("#exampleModal").modal("show");
            }
            else{
                console.log('asd')
                $("#exampleModal").modal("show");
            }
        },
        trackPage(path) {
            // this.$gtag.pageview(path); // Отправить событие отслеживания страницы для указанного пути
             this.$gtag.pageview({
                 page_path: path,
               })
           },
    },
    computed: {
        ...mapGetters(['IsLogged']),
    }
}

const app  = createApp(App)
app.use(store)
app.use(router)
app.use(VueGtag, {
    property: {
      id: "UA-249485243-2",
      params:{
        send_page_view: true,
      }
    }
  });
app.mixin(Mixin)

app.component('the-header', Header)
app.component('the-footer', Footer)
app.component('the-navbar', NavBar)
app.component('the-shop', Shop)
app.component('the-cases', Cases)
app.component('the-faq',FAQ)
app.component('how-steps', HowSteps)
app.component('the-account', Account)
app.component('cart-page', CartPage)
app.component('ops-modal', OpsModal)
app.component('log-reg', LogReg)
app.component('reg-modal', RegModal)
app.component('money-modal', MoneyModal)
app.component('payment-error', PaymentError)
app.component('output-modal', OutputModal)



app.mount('#app')

